import React from "react"

import styles from '@styles/pages/policy.module.scss'
import Layout from "@comp/layout"
import SEO from '@comp/seo'

import BtnBox from '@comp/btn_box'
import PageTitle from '@comp/page_title'
// markup
const PrivacyPolicyPage = ({ location }) => {
  return (
    <Layout location={location} pageName="policy">
      <SEO
        title={`プライバシーポリシー`}
        description={`MIRAI SAKE COMPANYのWebサイトに関するプライバシーポリシーはこちらをご覧ください。`}
        path={location.pathname}
      />
      <main className={styles.container}>
        <div className={styles.inner}>
          <PageTitle
            wrapClass={styles.title}
            label={"プライバシー\nポリシー"}
            labelEn='PRIVACY POLICY'
          />

          <div className={styles.contents}>
            <p>このプライバシーポリシー（以下「本ポリシー」といいます）は、MIRAI SAKE COMPANY株式会社（以下「当社」といいます）が、個人情報（個人情報保護法第２条第１項により定義された「個人情報」をいい、以下同様とします。）を利用・管理を行うについての方針を定めるものです。</p>
            <h2>第１条 総則</h2>
            <ol>
              <li>当社は、利用者（当社に個人情報を提供する全ての個人をいいます）の個人情報の保護の実現のため、個人情報保護法、各省庁ガイドラインその他関連する法令等を遵守します。</li>
              <li>本ポリシーは、当社が提供する各サービスの全てに適用されます。ただし、当社は、別途、各サービスの利用規約等において個人情報の取扱いについて規定し、または各サービス独自のプライバシーポリシーを設けることがあり（以下、これらを「個別ポリシー」といいます）、この場合、個別ポリシーも共に適用されます。本ポリシーと個別ポリシーに矛盾抵触する箇所がある場合、当該箇所については個別ポリシーが優先するものとします。</li>
              <li>当社は、現在、匿名加工情報を作成していません。当社が匿名加工情報を作成し、第三者提供する場合、当社は、別途「匿名加工情報取扱ルール」を作成し、遵守します。</li>
            </ol>

            <h2>第２条 個人情報の利用目的</h2>
            <ol>
              <li>
                <p>当社のサービス利用者の個人情報の利用目的</p>
                <ul>
                  <li>① サービスの円滑な提供および案内のため</li>
                  <li>② 必要な事項の通知のため</li>
                  <li>③ サービスおよび広告配信をカスタマイズするため</li>
                  <li>④ アンケートの実施、プレゼントの発送のため</li>
                  <li>⑤ マーケティングデータの調査・分析、サービスの改善、新たなサービス開発のため</li>
                  <li>⑥ 利用規約等に違反する利用を防止するため</li>
                  <li>⑦ 契約または法令に基づく権利の行使、義務の履行のため</li>
                </ul>
              </li>

              <li>
                <p>当社の取引先の法人の従業員または個人に関する個人情報の利用目的</p>
                <ul>
                  <li>① 業務上必要なご連絡、契約の履行、商談等のため</li>
                  <li>② 取引先情報の管理のため</li>
                </ul>
              </li>
              <li>
                <p>お問い合わせをいただいた個人に関する個人情報の利用目的</p>
                <ul>
                  <li>① お問い合わせ対応のため</li>
                </ul>
              </li>

              <li>
                <p>セミナー・イベント等にご参加いただく皆様に関する個人情報の利用目的</p>
                <ul>
                  <li>① セミナー・イベント等の円滑な運営のため</li>
                  <li>② セミナー・イベント等に関するご連絡、資料の発送のため</li>
                </ul>
              </li>
              <li>
                <p>当社のリクルートに応募いただいた個人に関する個人情報の利用目的</p>
                <ul>
                  <li>① リクルートに関する情報提供、ご連絡のため</li>
                </ul>
              </li>

              <li>前各号以外の個人情報の利用目的は、第８条記載のお問い合わせ先にお問い合わせください。</li>

              <li>当社は、前各項の利用目的を、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には、当社が別途定める方法により、通知または公表します。</li>
            </ol>
            
            <h2>第３条 個人情報の第三者提供</h2>
            <p>当社は、本ポリシーに記載するほか、個人情報保護法その他の法令により認められる場合を除き、個人情報を第三者に提供することはありません。</p>

            <h2>第４条 個人情報の共同利用</h2>
            <ol>
              <li>
                <p>当社は、当社の関連会社との間で、以下に定める範囲において、利用者の個人情報を共同利用することがあります。</p>
                <ul>
                  <li>
                    <p>① 共同して利用される個人情報の項目</p>
                    <p>当社が運営するサービスにて取得した個人情報の全て</p>
                  </li>
                  <li>
                    <p>② 共同して利用する者の範囲</p>
                    <p>株式会社未来酒店、その他当社の親会社、子会社、持分法適用会社ならびに、当社の親会社の小会社および持分法適用会社</p>
                  </li>
                  <li>
                    <p>③ 利用する者の利用目的</p>
                    <p>第２条所定の利用目的と同じ</p>
                  </li>
                  <li>
                    <p>④ 個人情報の管理について責任を有する者の名称</p>
                    <p>株式会社MIRAI SAKE COMPANY</p>
                  </li>
                </ul>
              </li>
            </ol>

            <h2>第５条 安全管理体制</h2>
            <p>当社は、個人情報への不正アクセス、個人情報の流出、紛失、改ざんなどのリスクに備え、セキュリティソフトの導入等、個人情報の安全管理のための措置を講じています。</p>

            <h2>第６条 Cookieの利用、アクセス解析</h2>
            <ol>
              <li>当社の運営するウェブサイト（以下「当社サイト」といいます）では、Cookieを利用し、当社サイトの利便性の向上、統計データの集積・分析に役立てています。利用者は、ブラウザの設定により、Cookieを無効にすることができますが、この場合、当社サイトの一部の機能に制約が生じることがあります。</li>
              <li>当社サイトは、Cookieを利用して、以下の広告配信を行う事業者に対し広告配信を委託し、行動ターゲティング広告のサービスを利用しています。このサービスを無効にするためには、それぞれリンク先にあるオプトアウトの手順に従ってください。<br />
              ・グーグル合同会社 <a href="https://support.google.com/ads/answer/2662922?hl=ja" target="_blank" rel="noopener noreferrer">https://support.google.com/ads/answer/2662922?hl=ja</a></li>
              <li>
              当社サイトでは、アクセス解析ツールである「Google Analytics」を利用しています。「Google Analytics」はトラフィックデータの収集のためにCookieを利用していますが、このトラフィックデータは匿名で収集されており、個人を特定するものではありません。詳細は、下記リンクをご覧ください。<br />
              <a href="https://www.google.com/intl/ja/policies/privacy/partners/" target="_blank" rel="noopener noreferrer">https://www.google.com/intl/ja/policies/privacy/partners/</a>
              </li>
            </ol>

            <h2>第７条 情報開示・訂正等の請求</h2>
            <ol>
              <li>
                <p>利用者は、利用者が当社に提供した個人情報について、以下に定める手続に従い、開示を請求することができます。</p>
                <ul>
                  <li> ① 当社所定の申請書（申請書の雛形は、当社の相談窓口宛（第１１条記載のとおり）に電子メールでご請求ください）に必要事項を記入し、本人確認（代理人による申請の場合、適正な代理人であることの確認書類が必要）のために必要な書類（運転免許証、パスポートなどの公的書類のコピー１点）を同封のうえ、相談窓口宛に郵送する方法によりご請求ください。インターネット上でのご請求は受け付けておりません。１回の申請ごとに手数料として１０００円を申し受けます。</li>
                  <li>② 前号に基づく請求につき本人確認がなされたときは、当社は、書面をもって、合理的な範囲で個人情報を開示します。ただし、個人情報保護法その他の法令により当社が開示義務を負わない場合または正当な理由なく同内容の請求が何度も繰り返される場合はこの限りではありません。</li>
                </ul>
              </li>
              <li>
                <p>当社の保有する利用者に関する個人情報の内容が事実と異なる場合、利用者は、以下に定める手続に従い、訂正、追加、削除を請求することができます。</p>
                <ul>
                  <li> ① 当社所定の申請書（申請書の雛形は、相談窓口宛に電子メールでご請求ください）に本人確認（代理人による申請の場合、適正な代理人であることの確認が必要）のために必要な書類を同封のうえ、相談窓口宛に郵送する方法によりご請求ください。</li>
                  <li>② 前号に基づく請求につき本人確認がなされたときは、当社は、合理的な範囲で遅滞なく調査を行い、その結果に基づき個人情報の訂正、追加、削除を行い、書面をもってご連絡します。ただし、個人情報保護法その他の法令により当社がこれらの義務を負わない場合、正当な理由なく同内容の請求が何度も繰り返される場合、または過度な技術的作業を要する場合はこの限りではありません。</li>
                </ul>
              </li>
            </ol>

            <h2>第８条 本ポリシーの改定</h2>
            <ol>
              <li>当社は、個人情報の取扱いに関する法令・ガイドラインの改正や運用状況を適宜見直し、継続的な改善に務めるものとし、随時、本ポリシーを改定することがあります。</li>
              <li>改定後の本ポリシーについては、当社のウェブサイトの適宜の場所に掲示その他分かりやすい方法により告知し、その時点から改定後の本ポリシーが適用されるものとします。</li>
              <li>前項にかかわらず、法令上利用者の同意が必要となる内容の変更を行うときは、別途当社が定める方法により、利用者の同意を取得します。</li>
            </ol>

            <h2>第９条 相談窓口</h2>
            <p>当社の個人情報の取扱いに関するご質問、ご意見、お困りごとその他個人情報の取扱いに関するご相談は、下記相談窓口までご連絡ください。なお、ご回答までに3日ほどお時間をいただくことがあります。</p>
            <p>【相談窓口】<br />〒111-0051 東京都台東区蔵前3-9-3臼井ビル5階<br />MIRAI SAKE COMPANY株式会社<br />個人情報相談窓口<br />Email：info@miraisake.com</p>

            <p>以上</p>
            <p>2020年 8月 18日 制定</p>
          </div>

          <div className={styles.contact}>
            <BtnBox
              to={'/contact/'}
            >
              お問い合わせ
            </BtnBox>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default PrivacyPolicyPage
